import React from 'react'

export default (...props) => {
	return (
			<svg x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33">
				<circle className="social-hover" cx="16.5" cy="16.5" r="16.5"/>
				<path fill="#FFFFFF" d="M14.8,12.7l4.7,3.8l-4.7,3.8C14.8,20.3,14.8,12.7,14.8,12.7z M25.5,13.7c0-2.4-1.7-4.3-3.9-4.3H11.4
	c-2.2,0-3.9,1.9-3.9,4.3v5.7c0,2.4,1.7,4.3,3.9,4.3h10.2c2.2,0,3.9-1.9,3.9-4.3C25.5,19.3,25.5,13.7,25.5,13.7z"/>
			</svg>
	)
}



import React from 'react'

export default (...props) => {
	return (
			<svg x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33">
				<g>
					<circle className="social-hover" cx="16.5" cy="16.5" r="16.5"/>
				</g>
				<g>
					<path fill="#FFFFFF" d="M22.7,8.4H10.5c-1.1,0-1.9,0.8-1.9,1.9v12.2c0,1,0.9,1.9,1.9,1.9h12.2
			c1.1,0,1.9-0.8,1.9-1.9V10.3C24.6,9.2,23.8,8.4,22.7,8.4z M20.1,10.9c0-0.3,0.2-0.5,0.5-0.5h1.5c0.3,0,0.5,0.2,0.5,0.5v1.5
			c0,0.3-0.2,0.5-0.5,0.5h-1.5c-0.3,0-0.5-0.2-0.5-0.5V10.9z M16.6,13.3c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1
			c-1.7,0-3.1-1.4-3.1-3.1C13.6,14.7,14.9,13.3,16.6,13.3z M23.1,22.4c0,0.3-0.2,0.5-0.5,0.5h-12c-0.3,0-0.5-0.2-0.5-0.5v-7.5h2
			c-0.3,0.4-0.3,1.1-0.3,1.5c0,2.7,2.2,4.9,4.9,4.9c2.7,0,4.9-2.2,4.9-4.9c0-0.4-0.1-1.1-0.4-1.5h2V22.4z"/>
				</g>
			</svg>
	)
}



import React from 'react';
import logoutIcon from '../../modules/HomePage/logout.png';
import logo from '../../modules/HomePage/logo-beta.png';
import {logout} from "../../lib/services/UserService";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";

class Header extends React.Component {
    renderHeaderMenu() {
        const userData = JSON.parse(localStorage.getItem('user')).data;
        let roles = userData.roles || [];
        if (roles.includes('admin')) {
            return <ul>
                <li><Link to="/member/api">API</Link></li>
                <li><Link to="/admin/accounts">Accounts</Link></li>
                <li><Link to="/admin/history">History</Link></li>
            </ul>
        } else {
            return <ul>
                <li><Link to="/member/api">API</Link></li>
            </ul>
        }
    }

    logout = (e) => {
        e.preventDefault();
        logout();
        this.props.history.push(`/login`);
    };

    render() {
        return (
            <header className="App-header">
                <a href="/">
                    <img src={logo} className="logo" alt=""/>
                </a>
                <div className="user-section">
                    {this.renderHeaderMenu()}
                    <span>{JSON.parse(localStorage.getItem('user')).data.name}</span>
                    <a href="/logout" onClick={this.logout} className={"logout"}>
                        <img src={logoutIcon} alt=""/>
                    </a>
                </div>
            </header>
        );
    }
}

const mapStateToProps = state => ({});

const actionCreators = {};

export default withRouter(connect(mapStateToProps, actionCreators)(Header));

import * as React from "react";
import Autosuggest from 'react-autosuggest';
import _ from "lodash";

const theme = {
    container: {
        position: 'relative'
    },
    input: {
        width: 240,
        height: 30,
        padding: '10px 20px',
        fontStyle: 'italic',
        fontWeight: 300,
        fontSize: 16,
        border: 'none',
        borderBottom: '1px solid #bdbdbd',
    },
    inputFocused: {
        outline: 'none'
    },
    inputOpen: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    },
    suggestionsContainer: {
        display: 'none'
    },
    suggestionsContainerOpen: {
        display: 'block',
        position: 'absolute',
        top: 27,
        width: 280,
        maxHeight: 300,
        backgroundColor: '#fff',
        color: '#050505',
        fontWeight: 300,
        fontSize: 16,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        zIndex: 2,
        overflow: 'scroll',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)'
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    suggestion: {
        cursor: 'pointer',
        padding: '10px 20px',
        borderBottom: '1px solid #eff0f1'
    },
    suggestionHighlighted: {
        backgroundColor: '#e9f2f4'
    }
};

class Autocomplete extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            suggestions: this.listOfSuggestionsWithoutSelected(),
            value: '',
            tags: this.listOfTags()
        }
    }
    handleDelete (i) {
        const tags = this.listOfTags().slice(0);
        tags.splice(i, 1);
        this.setState({ tags: tags });
        this.props.handleUpdateTypes(this.props.companyId, this.props.type, tags.map(item => item.name).join(','));
    }

    handleAddition (tag) {
        const tags = [].concat(this.listOfTags(), tag);
        this.setState({ tags: tags });
        this.props.handleUpdateTypes(this.props.companyId, this.props.type, tags.map(item => item.name).join(','));
    }

    listOfTags() {
        return this.props.tags.map(tag => { return { name: tag.id } });
    }

    listOfSuggestions(){
        return this.props.suggestions.map( suggestion => { return { name: suggestion }});
    }

    listOfSuggestionsWithoutSelected(){
        return _.xorBy(this.listOfSuggestions(), this.listOfTags(), 'name');
    }

    onChange = (event, { newValue }) => {
        this.setState({ value: newValue });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({ suggestions: this.getSuggestions(value) });
    };

    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    getSuggestions(value) {
        const regex = new RegExp('^' + value.trim(), 'i');
        return this.listOfSuggestionsWithoutSelected().filter(language => regex.test(language.name));
    }

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.handleAddition(suggestion);
    };

    renderSuggestion = suggestion => (
        <span>{suggestion.name}</span>
    );

    renderSelectedTags(){
        return this.state.tags.map((tag, index) => {
            if(tag.name !== '') {
                return <div className="react-tags__selected" key={index}>
                    <button type="button" onClick={() => this.handleDelete(index)}
                            className="react-tags__selected-tag" title="Click to remove tag">
                        <span className="react-tags__selected-tag-name">{tag.name}</span>
                    </button>
                </div>
            }
            return;
        })
    }

    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: 'Select ' + this.props.type,
            type: 'search',
            value,
            onChange: this.onChange
        };

        return <div className="autocomplete-section">
            <Autosuggest
                suggestions={suggestions}
                inputProps={inputProps}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                renderSuggestion={this.renderSuggestion}
                theme={theme}
                getSuggestionValue={() => ''}
                shouldRenderSuggestions={() => true}
                focusInputOnSuggestionClick={false}
            />
            <div className="react-tags">
                {this.renderSelectedTags()}
            </div>
        </div>
    }
}

export default Autocomplete;


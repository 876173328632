import React, { Component }  from 'react';
import './App.scss'
import UserForm from "../../lib/components/UserForm";
import { ReactComponent as LogoIcon } from "../RegisterPage/logo.svg";
import { Link } from 'react-router-dom'
import {loadUserCreditLimit} from '../../system/actions'
import { login }from "../../lib/services/UserService";
import {connect} from "react-redux";

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: ''
        }
    }

    handleSubmit = (user) => {
        login(user.email.value.trim(), user.password.value)
            .then(response => {
                this.setState({ error: '' });
                this.props.loadUserCreditLimit();
                this.props.history.push(`/`);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    this.setState({ error: 'Wrong email or password.' })
                }
            })
    };

    render(){
        return (
            <div className="login">
                <div className="login__body login-panel">
                    <div className="login-panel__logo">
                        <LogoIcon />
                    </div>
                    <div className="login__body--content">
                        <UserForm
                            handleSubmit={this.handleSubmit}
                            buttonName="Login"
                            errorMessage={this.state.error}
                            headerText="Log in"
                            emailCompleted=""
                        />
                        <div className="login__body--content__links">
                            <Link to="/forgot" className="forgot-password">Forgot password?</Link>
                            <Link to="/register" className="register-link">Create an account</Link>
                        </div>
                    </div>
                </div>
            </div>
        )}
}

const mapStateToProps = state => ({
});

const actionCreators = {
    loadUserCreditLimit: loadUserCreditLimit
};

export default connect(mapStateToProps, actionCreators)(LoginPage);

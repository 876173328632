import {
    REGISTER_SUCCESS,
    REGISTER_FAILURE
} from "./actions";

const initialState = {
    session: '',
    action: ''
};

export function registerReducer (state = initialState, action) {
    switch (action.type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                session: action.payload,
                action: 'success'
            };
        case REGISTER_FAILURE:
            return {
                ...state,
                session: action.payload,
                action: 'error'
            };
        default:
            return state;
    }
};

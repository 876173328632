import React, { Component }  from 'react';
import {setNewPassword} from "../../lib/services/UserService";
import './style.scss'
import { ReactComponent as LogoIcon } from "../RegisterPage/logo.svg";
import {register} from "../RegisterPage/actions";
import {connect} from "react-redux";
import queryString from 'query-string'

function ValidationMessage(props) {
    if (!props.valid) {
        return <div className="errors">{props.message}</div>
    }
    return null;
}

class NewPasswordPage extends Component {

    state = {
        password: "", passwordValid: false,
        rePassword: "", rePasswordValid: false,
        formValid: false,
        errorMsg: {}
    }

    validateForm = () => {
        const {passwordValid, rePasswordValid} = this.state;
        this.setState({
          formValid: passwordValid && rePasswordValid
        })
    }

    updatePassword = (password) => {
        this.setState({password}, this.validatePassword );
    }

    validatePassword = () => {
        const {password} = this.state;
        let passwordValid = true;
        let errorMsg = {...this.state.errorMsg};

        if (password.length < 8) {
            passwordValid = false;
            errorMsg.password = "Password is too short. Minimum length is 8 characters.";
        }

        if (password === this.state.rePassword && this.state.rePassword.length > 0) {
            this.state.rePasswordValid = true;
            this.state.errorMsg.rePassword = "";
        } else if (this.state.rePassword.length > 0) {
            this.state.rePasswordValid = false;
            this.state.errorMsg.rePassword = "Password doesn't match";
        }

        this.setState({passwordValid, errorMsg}, this.validateForm);
    }

    updateRePassword = (rePassword) => {
        this.setState({rePassword}, this.validateRePassword );
    }

    validateRePassword = () => {
        const {rePassword} = this.state;
        let rePasswordValid = true;
        let errorMsg = {...this.state.errorMsg};

        if (rePassword.length < 8) {
            rePasswordValid = false;
            errorMsg.rePassword = "Password is too short. Minimum length is 8 characters.";
        }

        if (rePassword !== this.state.password) {
            rePasswordValid = false;
            errorMsg.rePassword = "Password doesn't match";
        }

        this.setState({rePasswordValid, errorMsg}, this.validateForm);
    }

    sendForm = (ev) => {
        if (this.state.formValid) {
            let params = queryString.parse(this.props.location.search);

            setNewPassword(params.email, this.state.password, params.token)
                .then(response => {
                    this.props.history.push(`/login`);
                })
                .catch(error => {
                    this.setState({ action: 'error' })
                })
        }
        ev.preventDefault();
    };

    renderErrorMessage() {
        return (
            <div className="success-info">
                <h2>This link is no longer active</h2>
                <p>Unfortunatelly, this password reset link has expired. Please try again</p>
            </div>
        )
    }

    renderForm() {
        return <form className="forget-password__form" onSubmit={this.sendForm} >
                <div className="field">
                    <label className="input-label">New password</label>
                    <div className={!this.state.errorMsg.password || this.state.passwordValid ? "input-box" : "input-box error"}>
                        <input className="input-field" type="password" name="password" value={this.state.password} onChange={(e) => this.updatePassword(e.target.value)} data-required="true"/>
                    </div>
                    <div className="field__error">
                        <ValidationMessage valid={this.state.passwordValid} message={this.state.errorMsg.password} />
                    </div>
                </div>

                <div className="field">
                    <label className="input-label">Confirm password</label>
                    <div className={!this.state.errorMsg.rePassword || this.state.rePasswordValid ? "input-box" : "input-box error"}>
                        <input className="input-field" type="password" name="password" value={this.state.rePassword} onChange={(e) => this.updateRePassword(e.target.value)} data-required="true"/>
                    </div>
                    <div className="field__error">
                        <ValidationMessage valid={this.state.rePasswordValid} message={this.state.errorMsg.rePassword} />
                    </div>
                 </div>

                <div className="form-group">
                    <button className="btn" type="submit">
                        Change password
                    </button>
                </div>
            </form>
    }

    renderView() {
        if(this.state.action === 'error') {
            return this.renderErrorMessage();
        } else {
            return this.renderForm();
        }
    }

    render(){
        return (
            <div className="new-password">
                <div className="login-panel">
                    <div className="login-panel__logo">
                        <LogoIcon />
                    </div>
                    {this.renderView()}
                </div>
            </div>
        )}
}

const mapStateToProps = state => ({
    user: state.user,
});

const actionCreators = {
    register: register
};

export default connect(mapStateToProps, actionCreators)(NewPasswordPage);

import React, { Component } from 'react';
import { connect } from "react-redux";
import Header from "../../../lib/components/Header";
import ReactTable from "react-table";
import { fetchData } from "./reducer";
import './style.scss';
import "react-table/react-table.css";

class AdminAccounts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            columns: [
                {Header: "Id", accessor: "id"},
                {Header: "Email", accessor: "email"},
                {Header: "Roles", accessor: "roles", Cell: props => props.value.join(',')},
                {Header: "Used", accessor: "usage.count"},
                {Header: "Limit", accessor: "usage.limit"}
            ]
        };
    }

    handleOnFetchData({page, pageSize, sorted}, instance) {
        this.props.fetchData(page, pageSize, sorted);
    }

    render() {
        const {columns} = this.state;
        return (
            <div className="App-body">
                <div className="App admin-history">
                    <Header/>
                    <ReactTable
                        columns={columns}
                        manual={true}
                        data={this.props.data}
                        onFetchData={this.handleOnFetchData.bind(this)}
                        className="-striped -highlight"
                        defaultPageSize={50}
                        loading={this.props.historyLoader}
                        loadingText="Loading..."
                        showPagination={false}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    data: state.adminAccountReducer.accounts,
    historyLoader: state.reduxLoader.loaders.historyLoader
});

const actionCreators = {
    fetchData: fetchData,
};

export default connect(
    mapStateToProps,
    actionCreators
)(AdminAccounts);

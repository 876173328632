import axios from "axios"

export class ApiServiceConstructor {
    constructor() {
        this.axios = axios.create({
            // baseURL: API_URL,
            params: { },
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
            proxy: {
                host: 'app.getowl.co'
            }
        });

        this.axios.interceptors.response.use((response) => {
            return response;
        }, function (error) {
            console.log(error.response);
            if(error.response.status === 401 && error.response.config.url !== '/user') {
                window.location.href = '/login';
            }
            return Promise.reject(error);
        });
    }

    get(...args) {
        return this.axios.get.apply(this.axios, args)
    }

    post(...args) {
        return this.axios.post.apply(this.axios, args)
    }

    put(...args) {
        return this.axios.put.apply(this.axios, args)
    }

    delete(...args) {
        return this.axios.delete.apply(this.axios, args)
    }
}

export default new ApiServiceConstructor()

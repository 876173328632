import { combineReducers } from "redux";
import { searchHistoryReducer } from "../modules/query/search/reducers";
import { registerReducer } from "../modules/RegisterPage/reducer";
import { accountActivationReducer } from "../modules/AccountActivation/reducer";
import adminHistoryReducer from "../modules/Admin/History/reducer";
import adminAccountReducer from "../modules/Admin/Accounts/reducer";
import apiKeyReducer from "../modules/Member/ApiPage/reducer";

import { TAG_SUGGESTIONS_LOADED, USER_CREDIT_LIMIT_LOADED } from "./actions";
import { createForms } from 'react-redux-form';
import { reduxLoaderReducer } from 'redux-state-loader';

const initialUserState = {
    email: '',
    password: '',
    confirmPassword: ''
};

const initialModalListState = {
    listToSearch: '',
};

const initialState = {
    user: {
        count: 0,
        limit: 0,
    },
    tagsSuggestions: {}
};

export function systemReducer(state = initialState, action) {
    switch (action.type) {
        case TAG_SUGGESTIONS_LOADED:
            return {
                ...state,
                tagsSuggestions: action.payload
            };
        case USER_CREDIT_LIMIT_LOADED:
            return {
                ...state,
                user: action.payload
            };
        default:
            return state;
    }
}

export const reducers = combineReducers({
    reduxLoader: reduxLoaderReducer,
    system: systemReducer,
    searchHistory: searchHistoryReducer,
    registering: registerReducer,
    adminHistoryReducer: adminHistoryReducer,
    adminAccountReducer: adminAccountReducer,
    apiKeysReducer: apiKeyReducer,
    accountActivation: accountActivationReducer,
    ...createForms({
        user: initialUserState,
        modalList: initialModalListState
    }),
});

import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { history } from './lib/helpers/history';
import { PrivateRoute } from './lib/helpers/privateRoute';
import HomePage from './modules/HomePage/HomePage';
import LoginPage from './modules/LoginPage/LoginPage';
import AccountActivation from './modules/AccountActivation/AccountActivation';
import RegisterPage from './modules/RegisterPage/RegisterPage';
import ForgotPasswordPage from './modules/ForgotPasswordPage/ForgotPasswordPage';
import NewPasswordPage from './modules/NewPasswordPage/NewPasswordPage';
import AdminAccounts from './modules/Admin/Accounts/Accounts';
import AdminHistory from './modules/Admin/History/History';
import ApiPage from './modules/Member/ApiPage/ApiPage';

class App extends React.Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <PrivateRoute exact path="/" component={HomePage}/>
                    <PrivateRoute path='/admin/accounts' component={AdminAccounts}/>
                    <PrivateRoute path='/admin/history' component={AdminHistory}/>
                    <PrivateRoute path='/member/api' component={ApiPage}/>
                    <Route path="/login" component={LoginPage}/>
                    <Route path="/account" component={AccountActivation}/>
                    <Route path="/register" component={RegisterPage}/>
                    <Route path="/register?email=:email" component={RegisterPage}/>
                    <Route path='/forgot' component={ForgotPasswordPage}/>
                    <Route path='/new-password' component={NewPasswordPage}/>
                    <Redirect from="*" to="/"/>
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

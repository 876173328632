import BaseDecorator from "./BaseDecorator"

export default class SearchedCompanyDecorator extends BaseDecorator {

    get not_found() {
        return !this.error && !this.webpage && !this.company;
    }

    get loading() {
        return !this.finished;
    }
}

import * as React from "react";
import LeftArrow from './left-arrow'
import RightArrow from './right-arrow'
import './style.scss'
import KeyboardEventHandler from 'react-keyboard-event-handler';
import ReactTooltip from "react-tooltip";

class Navigation extends React.Component {
    keyEvent(key){
        switch(key) {
            case 'left':
                this.props.loadPreviousCompany();
                break;
            case 'right':
                this.props.loadNextCompany();
                break;
        }
    };

    render(){
        return <div className="navigation">
            <KeyboardEventHandler
                handleKeys={['left', 'right']}
                onKeyEvent={(key, e) => this.keyEvent(key)}
            />
            <button href="#" className={"left-arrow "} data-tip data-for={"previous-company"}
                    disabled={(this.props.previousCompanyExist() ? "" : "disabled")}
                    onClick={() => this.props.loadPreviousCompany()} >
                <LeftArrow />
                <ReactTooltip id={"previous-company"} type='light' place={"top"} effect="solid" className='react-tooltip-theme'>
                    Previous company
                </ReactTooltip>
            </button>
            <button href="#" className={"right-arrow "} data-tip data-for={"next-company"}
                    disabled={(this.props.nextCompanyExist() ? "" : "disabled")}
                    onClick={() => this.props.loadNextCompany()} >
                <RightArrow />
                <ReactTooltip id={"next-company"} type='light' place={"top"} effect="solid" className='react-tooltip-theme'>
                    Next company
                </ReactTooltip>
            </button>
        </div>
    }
};

export default Navigation;

import ApiService from "../lib/services/ApiService";
import { creditLimit } from "../lib/services/UserService";

export const TAG_SUGGESTIONS_LOADED = "TAG_SUGGESTIONS_LOADED";
export const USER_CREDIT_LIMIT_LOADED = "USER_CREDIT_LIMIT_LOADED";

export function getTagsSuggestions() {
    return dispatch => {
        ApiService
            .get(`/v1/tag/option`)
            .then(function (results) {
                dispatch({
                    type: TAG_SUGGESTIONS_LOADED,
                    payload: results.data
                });
            })
    }
}

export function loadUserCreditLimit() {
    return dispatch => {
        creditLimit()
            .then(results =>
                dispatch({
                    type: USER_CREDIT_LIMIT_LOADED,
                    payload: results.data
                })
            ).catch(error => {
                if (error.response && error.response.status === 403) {
                    dispatch({
                        type: USER_CREDIT_LIMIT_LOADED,
                        payload: {limit: 100, count: 100}
                    })
                }
            })
    }
}

import React from 'react'

export default (...props) => {
	return (
			<svg x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33">
				<circle class="social-hover" cx="16.5" cy="16.5" r="16.5"/>
				<g>
					<path fill="#FFFFFF" d="M20.6,20.1c-1,0.9-2.3,1.4-3.7,1.3c-3.1,0-5.4-2.3-5.4-5.3s2.2-5.2,5.4-5.2c1.3,0,2.7,0.5,3.7,1.3l0.7,0.6l3-3
		l-0.8-0.7c-1.8-1.6-4.2-2.5-6.6-2.4c-5.6,0-9.6,4-9.6,9.4s4.1,9.6,9.6,9.6c2.5,0,4.8-0.9,6.7-2.5l0.7-0.7l-3.1-3L20.6,20.1z"/>
					<circle fill="#FFFFFF" cx="16.8" cy="16.2" r="2.5"/>
				</g>
			</svg>
	)
}



import React, { Component }  from 'react';
import {ReactComponent as LogoIcon} from "../../modules/RegisterPage/logo.svg";

class ActivationMessage extends Component {
    render() {
        return <div className="register">
            <div className="login-panel">
                <div className="login-panel__logo">
                    <LogoIcon />
                </div>
                <div className="success-info">
                    <h2>Check your inbox and activate your account</h2>
                    <p>We sent you an email with activation link. Please check your inbox.</p>
                    <p>If you don't see the link, please refresh your inbox in 5 minutes or check the spam folder.</p>
                </div>
            </div>
        </div>
    }
}

export default ActivationMessage;

import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { register } from './actions'
import { ReactComponent as LogoIcon } from './logo.svg';
import './App.scss';
import UserForm from "../../lib/components/UserForm";
import ActivationMessage from "../../lib/components/ActivationMessage";

class RegisterPage extends Component {

    renderAlreadyExistMessage() {
        let error = this.props.registering.action;
        let errorMessage = <div className="errors">
            <span>Account has already existed. <a href="/login" >Log in instead of register.</a></span>
        </div>

        return error === 'error' ? errorMessage : '';
    }

    getEmailFromUrl() {
        let parametersFromUrl = new URLSearchParams(this.props.location.search).get('email');

        if (parametersFromUrl !== null && parametersFromUrl.trim() !== '') {
            let regex = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
            let checkEmail = decodeURIComponent(parametersFromUrl.split(' ').join('+')).match(regex);
            return checkEmail !== null ? checkEmail[0] : '';
        }

        return '';
    }

    handleSubmit = (register) => {
        this.props.register(register.email.value, register.password.value);
    };

    renderForm() {
        return <div className="register">
            <div className="login-panel">
                <div className="login-panel__logo">
                    <LogoIcon />
                </div>
                <div className="user-form">
                    <UserForm
                        handleSubmit={this.handleSubmit}
                        buttonName="Get started free"
                        errorMessage={this.renderAlreadyExistMessage()}
                        headerText="Create an account"
                        emailCompleted={this.getEmailFromUrl()}
                    />
                    <div className="user-form__terms">
                        By clicking this button, you accept
                        <a href="https://getowl.co/terms-of-service-owl/" target="_blank" rel="noopener noreferrer">
                            terms of service
                        </a>
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        if (this.props.registering.action === 'success') {
            return <ActivationMessage />
        } else {
            return this.renderForm();
        }
    }
}

const mapStateToProps = state => ({
    registering: state.registering,
});

const actionCreators = {
    register: register,
};

export default connect(mapStateToProps, actionCreators)(RegisterPage);

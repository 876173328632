import React from 'react'

export default (...props) => {
	return (
			<svg x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33">
				<circle className="social-hover" cx="16.5" cy="16.5" r="16.5"/>
				<path fill="#FFFFFF" d="M54.1-24l5,4l-5,4V-24z M65.4-23c0-2.5-1.8-4.5-4.1-4.5H50.5c-2.3,0-4.1,2-4.1,4.5v6c0,2.5,1.8,4.5,4.1,4.5
	h10.8c2.3,0,4.1-2,4.1-4.5V-23z"/>
				<g>
					<path fill="#FFFFFF" d="M20.7,31.8c0-0.5,0-2.3,0-4.6c0-1.6-0.5-2.6-1.1-3.1c3.7-0.4,7.6-1.8,7.6-8.2c0-1.8-0.6-3.3-1.7-4.5
		c0.2-0.4,0.7-2.1-0.2-4.4c0,0-1.4-0.4-4.6,1.7c-1.3-0.4-2.8-0.6-4.2-0.6c-1.4,0-2.9,0.2-4.2,0.6C9.2,6.6,7.8,7,7.8,7
		c-0.9,2.3-0.3,4-0.2,4.4c-1.1,1.2-1.7,2.7-1.7,4.5c0,6.4,3.9,7.8,7.6,8.2c-0.5,0.4-0.9,1.2-1.1,2.2c-1,0.4-3.4,1.2-4.9-1.4
		c0,0-0.9-1.6-2.6-1.7c0,0-1.6,0-0.1,1c0,0,1.1,0.5,1.9,2.4c0,0,1,3.2,5.6,2.2c0,1.4,0,2.4,0,2.8c0,0.2-0.1,0.4-0.2,0.6
		c1.4,0.4,2.8,0.6,4.3,0.6c1.5,0,3-0.2,4.4-0.6C20.8,32.3,20.7,32,20.7,31.8z"/>
				</g>
			</svg>
	)
}



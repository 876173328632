import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../../../lib/components/Header";
import ReactTable from "react-table";
import { fetchData } from "./reducer";
import './style.scss';
import "react-table/react-table.css";

class AdminHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            columns: [
                {Header: "Created at", accessor: "createdAt"},
                {Header: "Query", accessor: "query"},
                {Header: "Account", accessor: "account"},
                {Header: "Webpage", accessor: "webpage"},
                {Header: "Name", accessor: "name"},
                {Header: "Tags", accessor: "tags"}
            ]
        };
    }

    handleOnFetchData({page, pageSize, sorted}, instance) {
        this.props.fetchData(page, pageSize, sorted);
    }

    render() {
        const {columns, page} = this.state;
        return (
            <div className="App-body">
                <div className="App admin-history">
                    <Header/>
                    <div className="admin-history-wrapper">
                        <ReactTable
                            columns={columns}
                            manual={true}
                            data={this.props.search}
                            pages={this.props.pages}
                            onFetchData={this.handleOnFetchData.bind(this)}
                            className="-striped -highlight"
                            page={page}
                            onPageChange={page => this.setState({page})}
                            defaultPageSize={50}
                            loading={this.props.historyLoader}
                            loadingText="Loading..."
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    search: state.adminHistoryReducer.search,
    filters: state.adminHistoryReducer.filters,
    pages: state.adminHistoryReducer.pages,
    historyLoader: state.reduxLoader.loaders.historyLoader
});

const actionCreators = {
    fetchData: fetchData,
};

export default connect(
    mapStateToProps,
    actionCreators
)(AdminHistory);


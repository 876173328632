import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger'
import thunkMiddleware from "redux-thunk";
import { reducers } from './system/reducer'
import { reduxLoaderMiddleware } from 'redux-state-loader';

const loggerMiddleware = createLogger();
const loaderMiddleware = reduxLoaderMiddleware();

let middleware = [];
if (process.env.NODE_ENV === 'development') {
    middleware = [...middleware, thunkMiddleware, loggerMiddleware, loaderMiddleware];
} else {
    middleware = [...middleware, thunkMiddleware, loaderMiddleware];
}

export const store = createStore(
    reducers,
    applyMiddleware(...middleware)
);

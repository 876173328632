import React from 'react'

export default (...props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="16"
            x="0"
            y="0"
            enableBackground="new 0 0 9 16"
            version="1.1"
            viewBox="0 0 9 16"
            xmlSpace="preserve"
        >
            <path
                d="M1.7 8l7.2-6.9c.2-.2.2-.5 0-.7L8.6.1c-.2-.1-.5-.1-.7 0L.1 7.6c-.1.1-.1.3-.1.4 0 .1 0 .3.1.4l7.7 7.5c.2.2.5.2.7 0l.2-.2c.2-.2.2-.5 0-.7l-7-7z"
                className="st0"
            ></path>
        </svg>
    )
}



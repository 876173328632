import ApiService from "../../../lib/services/ApiService";
import {reduxLoaderActions} from "redux-state-loader";

export const SET_SEARCH = "search/SET_SEARCH";

export const fetchData = (page, pageSize, sorted) => {
    return dispatch => {
        dispatch(reduxLoaderActions.startLoading('historyLoader'));
        ApiService
            .get(`/v1/profile/history?page=${page}&size=${pageSize}`)
            .then(result => {
                dispatch({
                    type: SET_SEARCH,
                    payload: {
                        profiles: result.data.profiles,
                        pages: Math.ceil(result.data.count / pageSize)
                    }
                });
                dispatch(reduxLoaderActions.stopLoading('historyLoader'));
            })
    };
};

const initialState = {
    search: [],
    pages: 0,
    page: 0,
    filters: {
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH:
            return {
                ...state,
                search: action.payload.profiles,
                pages: action.payload.pages
            };
        default:
            return state;
    }
};

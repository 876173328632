import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClutchIcon from "./clutchIcon";
import FacebookIcon from "./facebookIcon";
import GithubIcon from "./githubIcon";
import InstagramIcon from "./instagramIcon";
import LinkedinIcon from "./linkedinIcon";
import TwitterIcon from "./twitterIcon";
import YoutubeIcon from "./youtubeIcon";
import ReactTooltip from 'react-tooltip'
import * as Icon from "@fortawesome/free-solid-svg-icons"
import ShowMoreText from './ShowMoreText'
import './Company.scss'
import CompanyTag from "./CompanyTag";
import "./CompanyTag.scss";
import _ from "lodash";

class Company extends React.Component {
    renderErrorMessage() {
        return (
            <div className="profile">
                <div className="error-message">
                    <strong>The website seems inactive</strong>
                    <p>
                        We’re really sorry, but the domain you tried to reach seems to be inactive or the process of
                        loading lasts too long. Go to the website or try another company.
                    </p>
                </div>
            </div>
        )
    }

    renderErrorAboutPersonalEmail() {
        return (
            <div className="profile">
                <div className="error-message">
                    <strong>Personal email address detected </strong>
                    <p>
                        We’re sorry, but we are unable to provide information about the company as it seems that this
                        isn’t a business email address. Let’s try another company.
                    </p>
                </div>
            </div>
        )
    }

    renderSocials(links, icon) {
        return links.slice(0, 1).map((link, index) =>
            <a href={link} key={index} target="_blank" rel="noopener noreferrer">{icon}</a>
        );
    }

    renderAdditionalCompanyInfo() {
        const links = this.props.companyProfile.webpage.links;
        if (_.union([links.blog.length, links.partner.length, links.jobs.length]).length === 1) {
            return <div></div>
        }

        return <div>
            <strong>Additional information:</strong>
            <ul className="additional-info">
                {links.blog.length > 0 ?
                    <li>
                        <a href={links.blog[0]} target="_blank" rel="noopener noreferrer">Blog</a>
                    </li> : ''}
                {links.partner.length > 0 ?
                    <li>
                        <a href={links.partner[0]} target="_blank" rel="noopener noreferrer">Partner Program</a>
                    </li> : ''}
                {links.jobs.length > 0 ?
                    <li>
                        <a href={links.jobs[0]} target="_blank" rel="noopener noreferrer">Hiring</a>
                    </li> : ''}
            </ul>
        </div>
    }

    renderLocationInfo() {
        return !_.isEmpty(this.props.companyProfile.company.locality) && <div>
            <div className="location inline" data-tip data-for={"location"}>
                <FontAwesomeIcon icon={Icon.faMapMarkerAlt} size="1x" color="#83abb7"/>
                {this.props.companyProfile.company.locality}
                <ReactTooltip id={"location"} place={"top"} effect="solid" className='react-tooltip-theme'>
                    <span>Location</span>
                </ReactTooltip>
            </div>
        </div>
    }

    renderFoundedYear() {
        return !_.isEmpty(this.props.companyProfile.company.founded) && <div className="founded">
            <strong>Founding year:</strong>
            {this.props.companyProfile.company.founded}
        </div>
    }

    renderCompanySize() {
        return !_.isEmpty(this.props.companyProfile.company.size) && <div>
            <div className="size inline" data-tip data-for={"companySize"}>
                <FontAwesomeIcon icon={Icon.faUsers} size="sm" color="#83abb7"/>
                {this.props.companyProfile.company.size}
            </div>
            <ReactTooltip id={"companySize"} place={"top"} effect="solid" className='react-tooltip-theme'>
                <span>Company size</span>
            </ReactTooltip>
        </div>
    }

    renderTitle() {
        return !_.isEmpty(this.props.companyProfile.webpage.title) && <div>
            <strong>Title:</strong>
            <ShowMoreText
                text={this.props.companyProfile.webpage.title}
            />
        </div>
    }

    renderDescription() {
        return !_.isEmpty(this.props.companyProfile.webpage.metaDescription) && <div>
            <strong>Description:</strong>
            <ShowMoreText
                text={this.props.companyProfile.webpage.metaDescription}
            />
        </div>
    }

    renderWebsiteKeywords() {
        return this.props.companyProfile.webpage.metaKeywords.length > 0 && <div>
            <strong>Website keywords:</strong>
            <ShowMoreText
                text={this.props.companyProfile.webpage.metaKeywords.join(", ")}
            />
        </div>
    }

    render() {
        if (this.props.companyProfile.company_exist) {
            return (
                <div className="company-profile">
                    <div className="logo">
                        <img src={"https://logo.clearbit.com/" + this.props.companyProfile.webpage.domain} alt=""/>
                    </div>
                    <div className="title">{this.props.companyProfile.company.name[0]}</div>
                    <div className="url">
                        <a href={this.props.companyProfile.webpage.url} target="_blank" rel="noopener noreferrer">
                            {this.props.companyProfile.webpage.url}
                        </a>
                    </div>
                    <div className="socials">
                        {this.renderSocials(this.props.companyProfile.webpage.social.clutch, <ClutchIcon/>)}
                        {this.renderSocials(this.props.companyProfile.webpage.social.facebook, <FacebookIcon/>)}
                        {this.renderSocials(this.props.companyProfile.webpage.social.github, <GithubIcon/>)}
                        {this.renderSocials(this.props.companyProfile.webpage.social.instagram, <InstagramIcon/>)}
                        {this.renderSocials(this.props.companyProfile.webpage.social.linkedin, <LinkedinIcon/>)}
                        {this.renderSocials(this.props.companyProfile.webpage.social.twitter, <TwitterIcon/>)}
                        {this.renderSocials(this.props.companyProfile.webpage.social.youtube, <YoutubeIcon/>)}
                    </div>
                    <div className="align-left">
                        {this.renderLocationInfo()}
                        {this.renderFoundedYear()}
                        {this.renderCompanySize()}
                        {this.renderTitle()}
                        {this.renderDescription()}
                        {this.renderWebsiteKeywords()}
                        <CompanyTag
                            type="tags"
                            label="Categories"
                            companyId={this.props.companyProfile.uuid}
                            companyTypes={this.props.companyProfile.company.type}
                            autocompleteSuggestions={this.props.autocompleteSuggestions}
                            handleUpdateTypes={this.props.handleUpdateTypes}
                        />
                        {this.renderAdditionalCompanyInfo()}
                    </div>
                </div>
            );
        } else if (this.props.companyProfile.hasPersonalMail) {
            return this.renderErrorAboutPersonalEmail();
        } else {
            return this.renderErrorMessage();
        }
    }
};

export default Company;

import React from 'react'

export default (...props) => {
	return (
			<svg x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33">
				<path className="social-hover" d="M16.5,0C7.4,0,0,7.4,0,16.5C0,25.6,7.4,33,16.5,33C25.6,33,33,25.6,33,16.5C33,7.4,25.6,0,16.5,0z M20.9,10.1
	c0,0-0.4,0-1.9,0c-1.4,0-1.4,1.1-1.4,1.1l0,2.7l3.3,0.1l-0.5,3.5h-2.8l0,7.3l-3.6,0l0-7.3l-3,0L11,14h3v-2.6c0-0.3,0-0.5,0.1-0.8
	c0.1-0.6,0.2-1.2,0.5-1.7c0.2-0.5,0.6-0.9,1-1.2C16,7.4,16.5,7.1,17,7c0.4-0.1,0.7-0.2,1.1-0.2c0.5,0,2.4,0,2.9,0.1L20.9,10.1z"/>
			</svg>
	)
}



import React, { Component }  from 'react';
import { activateAccount }from "./actions";
import { connect } from "react-redux";
import queryString from 'query-string';

class AccountActivation extends Component {
    componentWillMount() {
        let params = queryString.parse(this.props.location.search);
        this.props.activate(params.token)
    }

    render(){
        if(this.props.accountActivation.success) {
            this.props.history.push(`/`);
        }
        return (
            <div>
                {this.props.accountActivation.error}
            </div>
        )}
}

const mapStateToProps = state => ({
    accountActivation: state.accountActivation
});

const actionCreators = {
    activate: activateAccount
};

export default connect(mapStateToProps, actionCreators)(AccountActivation);

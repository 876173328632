import * as React from "react";
import './style.scss'
import ApiService from "../../../lib/services/ApiService";
import Pagination from "rc-pagination"
import ReactTooltip from 'react-tooltip'
import _ from "lodash"
import { ReactComponent as DeleteIcon } from "./deleteIcon.svg";

class SearchHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            csv: [],
        }
    }

    renderName(query) {
        if (query.loading) {
            return "loading..."
        }
        if (query.not_found) {
            return "not found"
        }
        return query.name ? query.name : query.webpage;
    };

    CSVexport() {
        return ApiService.get('v1/query/export').then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'owl-export.csv');
            document.body.appendChild(link);
            link.click();
        });
    }

    changePage = (current, pageSize) => {
        this.props.handleChangePage(false, current, pageSize);
    };

    removeCompanyFromSearch(event, queryId) {
        event.stopPropagation();
        this.props.removeCompanyFromSearch(queryId);
    }

    renderTooltipIfTextIsTooLong(name, value) {
        if (_.isEmpty(value) || value.length < 63) {
            return
        }
        return <ReactTooltip id={name} type='light' place={"top"} className='react-tooltip-theme'>
            <span>{value}</span>
        </ReactTooltip>
    }

    renderDeleteIcon(id) {
        return <div>
            <DeleteIcon
                fill={"#B85767"} data-tip data-for={"delete"}
                onClick={(event) => this.removeCompanyFromSearch(event, id)}
            />
            <ReactTooltip id={"delete"} place={"top"} effect="solid" className='react-tooltip-theme'>
                <span>Delete</span>
            </ReactTooltip>
        </div>
    }

    renderPaginatedItems() {
        return this.props.queries.map((query, index) => (
            <div className={"company-item " + (this.props.selectedCompany.uuid === query.id ? 'active' : '')}
                 key={query.id} onClick={() => this.props.selectCompany(query.id)}>
                <h6 data-tip data-for={'companyName' + index}>{this.renderName(query)}</h6>
                {this.renderTooltipIfTextIsTooLong(('companyName' + index), this.renderName(query))}
                <p data-tip data-for={'searchQuery' + index}>{query.query}</p>
                {this.renderTooltipIfTextIsTooLong(('searchQuery' + index), query.query)}
                {this.renderDeleteIcon(query.id)}
            </div>
        ))
    }

    renderExportButton() {
        return <div className="export-link">
            <a href="#" data-tip data-for={"export"} onClick={this.CSVexport}>Export</a>
            <ReactTooltip id={"export"} place={"top"} effect="solid" className='react-tooltip-theme'>
                <span>Export data to .csv</span>
            </ReactTooltip>
        </div>
    }

    renderNextButton() {
        return <div>
            <a data-tip data-for={"next"} className="rc-pagination-next rc-pagination-item-link"></a>
            <ReactTooltip id={"next"} place={"top"} effect="solid" className='react-tooltip-theme'>
                Next page
            </ReactTooltip>
        </div>
    }

    renderPreviousButton() {
        return <div>
            <a data-tip data-for={"previous"} className="rc-pagination-next rc-pagination-item-link"></a>
            <ReactTooltip id={"previous"} place={"top"} effect="solid" className='react-tooltip-theme'>
                Previous page
            </ReactTooltip>
        </div>
    }

    render() {
        return (
            <div className="chat-history">
                {this.renderExportButton()}
                {this.renderPaginatedItems()}
                <Pagination
                    total={this.props.total}
                    defaultPageSize={this.props.pageSize}
                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total}`}
                    onChange={this.changePage}
                    showTitle={false}
                    current={this.props.currentPage}
                    nextIcon={this.renderNextButton()}
                    prevIcon={this.renderPreviousButton()}
                />
            </div>
        );
    }
};

export default SearchHistory;

import React from 'react'

export default (...props) => {
	return (
			<svg x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33">
				<path className="social-hover" d="M16.5,0C7.4,0,0,7.4,0,16.5C0,25.6,7.4,33,16.5,33C25.6,33,33,25.6,33,16.5C33,7.4,25.6,0,16.5,0z M23.7,13.1
	c0,0.1,0,0.3,0,0.4c0,4.5-3.4,9.7-9.5,9.7c-1.9,0-3.6-0.6-5.1-1.5c0.3,0,0.5,0,0.8,0c1.6,0,3-0.5,4.1-1.5c-1.5,0-2.7-1-3.1-2.4
	c0.2,0,0.4,0.1,0.6,0.1c0.3,0,0.6,0,0.9-0.1c-1.5-0.3-2.7-1.7-2.7-3.4v0c0.5,0.3,1,0.4,1.5,0.4c-0.9-0.6-1.5-1.7-1.5-2.9
	c0-0.6,0.2-1.2,0.5-1.7c1.6,2.1,4.1,3.4,6.9,3.6C17,13.7,17,13.5,17,13.2c0-1.9,1.5-3.4,3.3-3.4c1,0,1.8,0.4,2.4,1.1
	c0.8-0.2,1.5-0.4,2.1-0.8c-0.2,0.8-0.8,1.5-1.5,1.9c0.7-0.1,1.3-0.3,1.9-0.5C24.9,12.1,24.3,12.7,23.7,13.1z"/>
			</svg>
	)
}



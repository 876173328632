import { activate, reloadUserLocalStorage } from "../../lib/services/UserService";
import { loadUserCreditLimit } from "../../system/actions"
export const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS";
export const ACTIVATION_FAILURE = "ACTIVATION_FAILURE";

export function activateAccount(token) {
    return dispatch => {
        activate(token)
            .then(function (results) {
                reloadUserLocalStorage();
                dispatch(loadUserCreditLimit());
                dispatch({
                    type: ACTIVATION_SUCCESS,
                    payload: true
                });
            }).catch(function (error) {
                dispatch({
                    type: ACTIVATION_FAILURE,
                    payload: error.message
                });
            })
    }
}

import BaseDecorator from "./BaseDecorator"

export default class CompanyDecorator extends BaseDecorator {

    get company_exist() {
        return this.webpage && this.company
    }

    get hasPersonalMail() {
        return this.email && (this.email.domain.disposable || this.email.domain.free)
    }

    applyCustomValues(values) {
        // values: [{key: value}]
        values.forEach(kv => {
            if (kv.key.startsWith('profile.company.type')) {
                let key = kv.key.replace('profile.company.type.', '');
                let tags = kv.value.split(',');
                let toTag = function (tag) {
                    return {'id': tag, 'score': 1.0};
                };
                this.company.type[key] = tags.map(tag => toTag(tag))
            }
        });
    }

}

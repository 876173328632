import * as React from "react";
import ReactTextMoreLess from 'react-text-more-less';

class ShowMoreText extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
        };
    }

    handleClick = () => {
        this.setState({ collapsed: !this.state.collapsed });
    };

    render() {
        return <ReactTextMoreLess
            collapsed={this.state.collapsed}
            text={this.props.text}
            lessHeight={110}
            showMoreElement={(
                <span>
                    ... <span onClick={this.handleClick} className="show-more-text">more</span>
                </span>
            )}
            showLessElement={(
                <span onClick={this.handleClick} className="show-more-text">less</span>
            )}
        />
    }
}

export default ShowMoreText;

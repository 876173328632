import ApiService from "../../lib/services/ApiService";
import { login }from "../../lib/services/UserService";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export function register(email, password) {
    return dispatch => {
        ApiService
            .post(`/v1/register`, { email: email.trim(), password: password })
            .then(function (results) {
                dispatch({
                    type: REGISTER_SUCCESS,
                    payload: results.data
                });
                login(email.trim(), password);
            }).catch(function (error) {
                dispatch({
                    type: REGISTER_FAILURE,
                    payload: error
                });
            })
    }
}

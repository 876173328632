import {
    ACTIVATION_SUCCESS,
    ACTIVATION_FAILURE
} from "./actions";

const initialState = {
    success: false,
    error: ''
};

export function accountActivationReducer (state = initialState, action) {
    switch (action.type) {
        case ACTIVATION_SUCCESS:
            return {
                ...state,
                success: action.payload,
            };
        case ACTIVATION_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

import React, { Component } from 'react';
import './style.scss'
import Header from "../../../lib/components/Header";
import { connect } from "react-redux";
import { fetchApiKeys } from "./reducer";
import { bindActionCreators } from "redux";

class ApiPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            apikeys: []
        };
    }

    componentDidMount() {
        const {fetchApiKeys} = this.props;
        fetchApiKeys();
    }

    renderView() {
        return (
            <div class={"apikey"}>{(this.props.apikeys || []).map(apikey => {
                return (
                    <div>{apikey.apiKey}</div>
                );
            })}</div>
        );
    }

    render() {
        return (
            <div className="App-body">
                <div className="App admin-history">
                    <Header/>
                    <div class="apikeys">
                        <div class={"title"}>Your API Key</div>
                        {this.renderView()}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {apikeys: state.apiKeysReducer.apikeys};
};

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchApiKeys: fetchApiKeys
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApiPage);

import React from 'react'

export default (...props) => {
	return (
			<svg x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33">
				<circle className="social-hover" cx="16.5" cy="16.5" r="16.5"/>
				<g>
					<rect x="9.4" y="11.9" fill="#FFFFFF" width="2.9" height="10"/>
					<path fill="#FFFFFF" d="M20.3,11.8c-1.2-0.1-2.3,0.5-2.9,1.6v-1.5h-3.3c0.1,0.8,0,10,0,10h3.3v-5.5c0-0.3,0-0.6,0.1-0.8
		c0.2-0.7,0.9-1.2,1.6-1.2c1.1,0,1.7,0.9,1.7,2.3v5.2h3.3v-5.6C24.1,13.2,22.5,11.8,20.3,11.8z"/>
					<circle fill="#FFFFFF" cx="10.7" cy="8.7" r="1.8"/>
				</g>
			</svg>
	)
}



import React from 'react';
import './InternalServer.css';
import logo from "../../../../modules/HomePage/logo-beta.png";

const InternalServer = (props) => {
    return (
        <div className={'internalServer'}>
            <img src={logo} className="logo" alt="" />
            <strong>Oops! It seems that Owl is unavailable at the moment.</strong>
            <p>We’re sorry but we cannot provide relevant information right now. Please refresh the page or try in a few minutes. We’re working on restoring the system.</p>
            <p>If Owl is still unavailable in a few minutes, please contact us on hello@getowl.co</p>
        </div>
    )
};

export default InternalServer;

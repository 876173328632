import * as React from "react";
import "./UserForm.scss";
import { isEmail } from "../validators/emailValidator"

function ValidationMessage(props) {
    if (!props.valid) {
        return(
            <div className="errors">{props.message}</div>
        )
    }
    return null;
}

class UserForm extends React.Component {
    state = {
        email: this.props.emailCompleted, emailValid: this.props.emailCompleted !== "" ? true : false,
        password: "", passwordValid: false,
        formValid: false,
        errorMsg: {}
    }

    validateForm = () => {
        const {emailValid, passwordValid} = this.state;
        this.setState({
            formValid: emailValid && passwordValid
        })
    }

    updateEmail = (email) => {
        this.setState({email}, this.validateEmail );
    }

    validateEmail = () => {
        const {email} = this.state;
        let emailValid = true;
        let errorMsg = {...this.state.errorMsg}

        if (!isEmail(email) && email !== "admin") {
            emailValid = false;
            errorMsg.email = 'Email is required'
        }

        this.setState({emailValid, errorMsg}, this.validateForm)
    }

    updatePassword = (password) => {
        this.setState({password}, this.validatePassword)
    }

    validatePassword = () => {
        const {password} = this.state;
        let passwordValid = true;
        let errorMsg = {...this.state.errorMsg};

        if (password.length < 8) {
            passwordValid = false;
            errorMsg.password = "Password is too short. Minimum length is 8 characters.";
        }

        this.setState({passwordValid, errorMsg}, this.validateForm);
    }

    renderErrorMessage() {
        if (this.props.errorMessage !== '') {
            return <div className="errors">
                <span>{this.props.errorMessage}</span>
            </div>
        }
    }

    sendForm = (ev) => {
        if (this.state.formValid) {
            this.props.handleSubmit(ev.target);
        } else {
            let errorMsg = {...this.state.errorMsg};

            if (!isEmail(this.state.email) && this.state.email !== "admin") {
                errorMsg.email = "Email is required.";
                this.setState({emailValid: false, errorMsg}, this.validateForm);
            }

            if (this.state.password.length < 8) {
                errorMsg.password = "Password is too short. Minimum length is 8 characters.";
                this.setState({passwordValid: false, errorMsg}, this.validateForm);
            }
        }
        ev.preventDefault();
    };

    render() {
        return <form className="user-form" onSubmit={this.sendForm} >
            <div className="field">
                <h3>{this.props.headerText}</h3>
                <label className="input-label">Your email</label>
                <div className={!this.state.errorMsg.email || this.state.emailValid ? "input-box" : "input-box error"}>
                    <input className="input-field" type="text" name="email" value={this.state.email} onChange={(e) => this.updateEmail(e.target.value)} data-required="true" />
                </div>
                <div className="field__error">
                    <ValidationMessage valid={this.state.emailValid} message={this.state.errorMsg.email} />
                </div>
            </div>
            <div className="field">
                <label className="input-label">Password</label>
                <div className={!this.state.errorMsg.password || this.state.passwordValid ? "input-box" : "input-box error"}>
                    <input className="input-field" type="password" name="password" value={this.state.password} onChange={(e) => this.updatePassword(e.target.value)} data-required="true"/>
                </div>
                <div className="field__error">
                    <ValidationMessage valid={this.state.passwordValid} message={this.state.errorMsg.password} />
                </div>
                <div className="field__error form-error">
                    {this.renderErrorMessage()}
                </div>
            </div>
            <div className="form-group">
                <button className="btn" type="submit">
                    {this.props.buttonName}
                </button>
            </div>
        </form>

    }
};

export default UserForm;
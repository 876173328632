import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import './search-bar.scss';
import ModalList from "../../../lib/components/ModalList"
import { ReactComponent as SearchArrow } from './search_arrow.svg'
import _ from "lodash"

class SearchBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            validationError: false
        }
    }

    handleSearch(value) {
        if (_.isEmpty(value)) {
            this.setState({validationError: true})
        } else {
            this.props.updateInputValue('');
            this.props.handleSearchCompany(value);
        }
    }

    handleSubmit = () => {
        this.handleSearch(this.props.searchHistory.value);
    };

    onKeyPressed = (e) => {
        switch (e.keyCode) {
            case 13: {
                this.handleSearch(this.props.searchHistory.value);
                break;
            }
            default:
                return;
        }
    };

    getSuggestionValue(suggestion) {
        return suggestion.value;
    }

    renderSuggestion(suggestion) {
        return (
            <div className='suggestion-item'>
                <span>{suggestion.label}</span>
                <small>{suggestion.value}</small>
            </div>
        );
    }

    onChange = (event, {newValue}) => {
        this.setState({validationError: false});
        this.props.updateInputValue(newValue);
    };

    onSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
        if (method !== 'enter') {
            this.handleSearch(suggestion.value);
        }
    };

    searchHistoryIsEmpty() {
        return this.props.searchHistory.count < 1
    }

    showInstruction() {
        if (this.searchHistoryIsEmpty()) {
            return <div className="search-instruction">
                <SearchArrow width={60}/>
                <div><p>Enter an url, domain or email address to start<br/>searching for information</p></div>
            </div>
        }
    }

    showErrors() {
        return this.state.validationError && <div className="errors">
            Enter an url, domain or email address
        </div>
    }

    render() {
        const inputProps = {
            placeholder: 'Type in url, domain or email...',
            value: this.props.searchHistory.value,
            onChange: this.onChange,
            onKeyDown: this.onKeyPressed,
        };

        return (
            <div className="search-bar">
                <div className={"bar " + (this.state.validationError && "error")}>
                    <Autosuggest
                        suggestions={this.props.searchHistory.suggestions}
                        onSuggestionSelected={this.onSuggestionSelected}
                        onSuggestionsFetchRequested={this.props.loadSuggestions}
                        onSuggestionsClearRequested={this.props.clearSuggestions}
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestion={this.renderSuggestion}
                        inputProps={inputProps}
                    />
                    <ModalList
                        userCreditLimit={this.props.userCreditLimit}
                        loadSearchHistory={this.props.loadSearchHistory}
                    />
                    <button onClick={this.handleSubmit}>Search</button>
                </div>
                {this.showErrors()}
                {this.showInstruction()}
            </div>
        );
    }
}

export default SearchBar;

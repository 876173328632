import React, { Component } from 'react';
import ReactModal from 'react-modal';
import ApiService from "../../lib/services/ApiService";
import "./ModalListStyle.scss"
import { Control, Errors, Form } from "react-redux-form";
import _ from "lodash"
import ReactTooltip from "react-tooltip";

class ModalList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal() {
        this.setState({showModal: true});
    }

    handleCloseModal() {
        this.setState({showModal: false});
    }

    handleSubmit = (modalList) => {
        const regex = /^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|(?!:\/\/))([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.([a-zA-Z]{2,11})?)|^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/im
        const listOfElements = _.map(this.splitListOfElements(modalList.listToSearch), _.trim);
        const acceptableElements = _.filter(listOfElements, obj => regex.test(obj));

        ApiService
            .post(`/v1/query/batch`, {queries: acceptableElements})
            .then(() => {
                this.props.loadSearchHistory(0);
                this.handleCloseModal();
            })
    };

    myTextArea = (props) => {
        return <textarea
            placeholder={"Paste a list of domains, websites or email addresses here (up to 2000 records)."}
            {...props}
        />
    };

    splitListOfElements(val) {
        return val.split(/[ ,;\n\s+]+/);
    }

    attach(node) {
        node.reset();
    }

    renderAddListButton() {
        return <div>
            <a href={"#"} className={"open-modal"} data-tip data-for={"add-list"} onClick={this.handleOpenModal}>
                add many
            </a>
            <ReactTooltip id={"add-list"} type='light' place={"top"} effect="solid" className='react-tooltip-theme'>
                <span>Check multiple leads at once</span>
            </ReactTooltip>
        </div>
    }

    render = () => {
        return <div className={"modal-list"}>
            {this.renderAddListButton()}
            <ReactModal
                isOpen={this.state.showModal}
                contentLabel="Minimal Modal Example"
                className="Modal"
                overlayClassName="Overlay"
            >
                <h3>Paste a list</h3>
                <Form
                    model="modalList"
                    onSubmit={(modalList) => this.handleSubmit(modalList)}
                    getRef={(node) => this.attach(node)}
                >
                    <Control.textarea
                        model="modalList.listToSearch"
                        required
                        component={this.myTextArea}
                        validateOn="blur"
                        validators={{
                            length: (val) => this.splitListOfElements(val).length <= 2000,
                            creditValidation: (val) => this.splitListOfElements(val).length <= this.props.userCreditLimit
                        }}
                    />
                    <Errors
                        className="errors"
                        model="modalList.listToSearch"
                        show="touched"
                        messages={{
                            length: 'You added more than 2000 records - delete some of them.',
                            creditValidation: `You’ve exceeded your search limit (${this.props.userCreditLimit} searches left). Delete some records.`,
                        }}
                    />
                    <div className="buttons">
                        <a href={"#"} onClick={this.handleCloseModal}>Cancel</a>
                        <button type="submit">Add</button>
                    </div>
                </Form>
            </ReactModal>
        </div>
    }
}

export default ModalList;

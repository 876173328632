import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userData } from '../../lib/services/UserService'
import {loadUserCreditLimit} from '../../system/actions'
import { loadSearchHistory, selectCompany, loadNextCompany, loadPreviousCompany,
    loadSuggestions, updateInputValue, clearSuggestions, searchCompany, updateCompanyTypes, removeCompanyFromSearch,
    previousCompanyExist, nextCompanyExist
} from '../query/search/actions'
import { getTagsSuggestions } from '../../system/actions'
import SearchHistory from '../../modules/query/search/SearchHistory'
import Company from '../../lib/components/Company/Company'
import './App.scss';
import SearchBar from "../../modules/query/search/SearchBar";
import Navigation from "../../lib/components/navigation/Navigation";
import MyLoader from "../../lib/components/MyLoader";
import Header from "../../lib/components/Header";
import LimitBelt from "../../lib/components/LimitBelt/LimitBelt";
import ActivationMessage from "../../lib/components/ActivationMessage";

class HomePage extends React.Component {
    componentDidMount() {
        this.props.loadSearchHistory(0);
        this.props.getTagsSuggestions();
        this.props.loadUserCreditLimit();
    }

    renderCompany() {
        if(this.props.companyLoader) {
            return <div className="company-profile">
                <MyLoader />
            </div>
        } else {
            return <Company
                autocompleteSuggestions={this.props.system.tagsSuggestions}
                handleUpdateTypes={this.props.updateCompanyTypes}
                companyProfile={this.props.searchHistory.selectedCompany}
            />
        }
    }

    renderBody(){
        if(this.props.searchHistory.count < 1){
            return <div></div>
        }

        return <div className="body">
            <div className="first-section">
                <Navigation
                    loadPreviousCompany={this.props.loadPreviousCompany}
                    loadNextCompany={this.props.loadNextCompany}
                    previousCompanyExist={this.props.previousCompanyExist}
                    nextCompanyExist={this.props.nextCompanyExist}
                />

            </div>
            <div className="second-section">
                <SearchHistory
                    total={this.props.searchHistory.count}
                    pageSize={this.props.searchHistory.pageSize}
                    currentPage={this.props.searchHistory.currentPage}
                    queries={this.props.searchHistory.queries}
                    selectedCompany={this.props.searchHistory.selectedCompany}
                    selectCompany={this.props.selectCompany}
                    handleChangePage={this.props.loadSearchHistory}
                    removeCompanyFromSearch={this.props.removeCompanyFromSearch}
                />
                <div className="right-panel">
                    {this.renderCompany()}
                </div>
            </div>
        </div>
    }

    userIsActive(){
        return userData().activated
    }

    render(){
        if(this.userIsActive()){
            return (
                <div className="App-body">
                    <LimitBelt
                        count={this.props.system.user.count}
                        limit={this.props.system.user.limit}
                    />
                    <div className="App">
                        <Header />
                        <SearchBar
                            searchHistory={this.props.searchHistory}
                            loadSuggestions={this.props.loadSuggestions}
                            updateInputValue={this.props.updateInputValue}
                            clearSuggestions={this.props.clearSuggestions}
                            handleSearchCompany={this.props.handleSearchCompany}
                            userCreditLimit={(this.props.system.user.limit - this.props.system.user.count)}
                            loadSearchHistory={this.props.loadSearchHistory}
                        />
                        {this.renderBody()}
                    </div>
                </div>
            );
        } else {
            return <ActivationMessage />
        }
    }
}

const mapStateToProps = state => ({
    system: state.system,
    searchHistory: state.searchHistory,
    companyLoader: state.reduxLoader.loaders.companyLoader
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getTagsSuggestions: getTagsSuggestions,
    loadUserCreditLimit: loadUserCreditLimit,
    loadSearchHistory: loadSearchHistory,
    selectCompany: selectCompany,
    removeCompanyFromSearch: removeCompanyFromSearch,
    updateCompanyTypes: updateCompanyTypes,
    handleSearchCompany: searchCompany,
    loadPreviousCompany: loadPreviousCompany,
    loadNextCompany: loadNextCompany,
    loadSuggestions: loadSuggestions,
    updateInputValue: updateInputValue,
    clearSuggestions: clearSuggestions,
    previousCompanyExist: previousCompanyExist,
    nextCompanyExist: nextCompanyExist
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage);

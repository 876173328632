import React, { Component } from 'react';
import { sendResetPasswordLink } from "../../lib/services/UserService";
import './style.scss'
import { ReactComponent as LogoIcon } from "../RegisterPage/logo.svg";
import { isEmail } from "../../lib/validators/emailValidator";

function ValidationMessage(props) {
    if (!props.valid) {
       return <div className="errors">{props.message}</div>
    }
    return null;
}

class ForgotPasswordPage extends Component {
    state = {
        email: "", emailValid: false,
        formValid: false,
        errorMsg: {}
    }

    validateForm = () => {
        const {emailValid} = this.state;
        this.setState({
            formValid: emailValid
        })
    }

    updateEmail = (email) => {
        this.setState({email}, this.validateEmail );
    }

    validateEmail = () => {
        const {email} = this.state;
        let emailValid = true;
        let errorMsg = {...this.state.errorMsg}

        if (!isEmail(email) && email !== "admin") {
            emailValid = false;
            errorMsg.email = 'Email is required'
        }

        this.setState({emailValid, errorMsg}, this.validateForm)
    }

    renderErrorMessage() {
        if (this.props.errorMessage !== '') {
            return <div className="errors">
                <span>{this.props.errorMessage}</span>
            </div>
        }
    }

    sendForm = (ev) => {
        if (this.state.formValid) {
            sendResetPasswordLink(ev.target.email.value)
                .then(response => {
                    this.setState({action: 'success'});
                })
                .catch(error => {
                    this.setState({action: 'error'});
                })
        } else {
            let errorMsg = {...this.state.errorMsg};

            if (!isEmail(this.state.email) && this.state.email !== "admin") {
                errorMsg.email = "Email is required.";
                this.setState({emailValid: false, errorMsg}, this.validateForm);
            }

        }
        ev.preventDefault();
    };

    renderSuccessInfo() {
        return (
            <div className="success-info">
                <h2>Check your inbox and activate your account</h2>
                <p>We sent you an email with activation link. Please check your inbox.</p>
                <p>If you don't see the link, please refresh your inbox in 5 minutes or check the spam folder.</p>
            </div>
        )
    }

    renderErrorMessage() {
        if (this.state.action === 'error') {
            return <div className="errors">
                <span>Account not found. Please check the spelling</span>
            </div>
        }
    }

    renderForm() {
        return <form className="forget-password__form" onSubmit={this.sendForm} >
            <div className="field">
                <h3>Reset your password</h3>
                <p>Enter email connected with your account to reset your password</p>
            </div>
            <div className="field">
                <label className="input-label">Your email</label>
                <div className={!this.state.errorMsg.email || this.state.emailValid ? "input-box" : "input-box error"}>
                    <input className="input-field" type="text" name="email" value={this.state.email} onChange={(e) => this.updateEmail(e.target.value)} data-required="true" />
                </div>
                <div className="field__error">
                    <ValidationMessage valid={this.state.emailValid} message={this.state.errorMsg.email} />
                </div>
                <div className="field__error form-error">
                    {this.renderErrorMessage()}
                </div>
            </div>
            <div className="form-group">
                <button className="btn" type="submit">
                    Reset password
                </button>
            </div>
        </form>
    }

    renderView() {
        if (this.state.action === 'success') {
            return this.renderSuccessInfo();
        } else {
            return this.renderForm();
        }
    }

    render() {
        return (
            <div className="forget-password">
                <div className="login-panel">
                    <div className="login-panel__logo">
                        <LogoIcon />
                    </div>
                    {this.renderView()}
                </div>
            </div>
        )
    }
}

export default ForgotPasswordPage

import * as React from "react";
import Autocomplete from "../Autocomplete"
import pencil from './pencil.png'
import listensToClickOutside from 'react-onclickoutside';
import ReactTooltip from 'react-tooltip'

class CompanyTag extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            autocomplete: false,
        }
    }

    handleClickOutside = (event) => {
        this.setState({autocomplete: false})
    };

    renderTagsWithScore(list) {
        return list.filter(obj => typeof (obj) !== 'undefined' && obj.id !== '').map(object => {
            return <span key={object.id}>{object.id} {((object.score || 1) * 100).toFixed(0)}% </span>
        })
    }

    showAutocompleteEditButton() {
        return <a href="/#" className={"edit"} data-tip data-for={"edit"} onClick={(e) => this.toggleAutocomplete(e)}>
            <img src={pencil} alt=""/>
            <ReactTooltip id={"edit"} type='light' place={"top"} effect="solid" className='react-tooltip-theme'>
                <span>Edit</span>
            </ReactTooltip>
        </a>
    }

    toggleAutocomplete(event) {
        event.preventDefault();
        this.setState({autocomplete: !this.state.autocomplete});
    }

    renderContentSection() {
        if (!this.state.autocomplete) {
            return <div className="tags">
                {this.renderTagsWithScore(this.props.companyTypes[this.props.type])}
            </div>
        } else {
            return <Autocomplete
                companyId={this.props.companyId}
                type={this.props.type}
                tags={this.props.companyTypes[this.props.type]}
                suggestions={this.props.autocompleteSuggestions[this.props.type]}
                handleUpdateTypes={this.props.handleUpdateTypes}
            />
        }
    }

    render() {
        return <div>
            <strong>{this.props.label}: {this.showAutocompleteEditButton()}</strong>
            {this.renderContentSection()}
        </div>
    }
};

export default listensToClickOutside(CompanyTag);

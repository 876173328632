import ApiService from '../../../lib/services/ApiService.js'
import CompanyDecorator from '../../../lib/decorators/CompanyDecorator'
import _ from 'lodash';
import { reduxLoaderActions } from 'redux-state-loader';
import { loadUserCreditLimit } from '../../../system/actions'

export const SEARCH_HISTORY_LOADED = "SEARCH_HISTORY_LOADED";
export const SELECTED_COMPANY = "SELECTED_COMPANY";

export const UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE';
export const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS';
export const MAYBE_UPDATE_SUGGESTIONS = 'MAYBE_UPDATE_SUGGESTIONS';
export const LOAD_SUGGESTIONS_BEGIN = 'LOAD_SUGGESTIONS_BEGIN';
export const UPDATED_CUSTOM_VALUES = 'UPDATED_CUSTOM_VALUES';

export function loadSearchHistoryData(page = 1, pageSize = 20) {
    return ApiService
        .get(`/v1/query/profile/list?page=${page - 1}&size=${pageSize}`)
}

export function loadSearchHistory(companyIndex = false, page = 1, pageSize = 20) {
    return dispatch => {
        dispatch(reduxLoaderActions.startLoading('companyLoader'));

        loadSearchHistoryData(page, pageSize)
            .then(function (results) {
                dispatch({
                    type: SEARCH_HISTORY_LOADED,
                    payload: results.data,
                    currentPage: page,
                    pageSize: pageSize
                });

                dispatch(reduxLoaderActions.stopLoading('companyLoader'));

                if (Number.isInteger(companyIndex) && results.data.profiles.length > 0) {
                    dispatch(selectCompany(results.data.profiles[companyIndex].id))
                }
            })
    }
}

export const selectCompany = (queryId, page = null) => {
    return (dispatch, getState) => {
        const {currentPage, pageSize} = getState().searchHistory;
        dispatch(reduxLoaderActions.startLoading('companyLoader'));

        ApiService.get(`/v1/query/${queryId}/profile`)
            .then(profile => {
                dispatch({
                    type: SELECTED_COMPANY,
                    payload: new CompanyDecorator({uuid: queryId, ...profile.data})
                });
                dispatch(loadUserCreditLimit());
                dispatch(loadCustomValues(queryId));
                dispatch(loadSearchHistory(false, (page === null ? currentPage : page), pageSize));
                dispatch(reduxLoaderActions.stopLoading('companyLoader'));
            });
    }
};

export const removeCompanyFromSearch = (queryId) => {
    return (dispatch, getState) => {
        const {currentPage, pageSize} = getState().searchHistory;
        const idCurrentCompany = getState().searchHistory.selectedCompany.uuid;
        dispatch(reduxLoaderActions.startLoading('companyLoader'));

        ApiService.delete(`/v1/query/${queryId}`)
            .then(profile => {
                dispatch(loadSearchHistory(false, currentPage, pageSize));
                if (queryId === idCurrentCompany) {
                    dispatch(reduxLoaderActions.stopLoading('companyLoader'));
                    dispatch(reduxLoaderActions.startLoading('companyLoader'));
                    dispatch({
                        type: SELECTED_COMPANY,
                        payload: {}
                    });
                    dispatch(loadNextCompany(idCurrentCompany));
                }
            });
    }
};

export const searchCompany = (query) => {
    return dispatch => {
        ApiService
            .post(`/v1/query`, {q: query})
            .then((results) => {
                dispatch(selectCompany(results.data.id, 1));
            })
    }
};

export const loadCustomValues = (queryId) => {
    return dispatch => {
        ApiService
            .get(`/v1/query/${queryId}/value`)
            .then(result =>
                dispatch({
                    type: UPDATED_CUSTOM_VALUES,
                    payload: result.data
                })
            );
    }
};

export const updateCompanyTypes = (queryId, key, value) => {
    return dispatch => {
        ApiService
            .put(`/v1/query/${queryId}/value`, {
                key: "profile.company.type." + key,
                value: value
            })
            .then(result =>
                dispatch(loadCustomValues(queryId))
            );
    }
};

export function loadNextCompany(currentCompanyId = null) {
    return (dispatch, getState) => {
        const companies = getState().searchHistory.queries;
        const uuidCompany = currentCompanyId || getState().searchHistory.selectedCompany.uuid;
        const indexSelectedCompany = _.findIndex(companies, {uuid: uuidCompany});

        if (companies.length > indexSelectedCompany + 1) {
            dispatch(selectCompany(companies[indexSelectedCompany + 1].uuid))
        } else {
            const {currentPage, pageSize} = getState().searchHistory;
            dispatch(loadSearchHistory(0, currentPage + 1, pageSize));
        }
    }
}

export function loadPreviousCompany() {
    return (dispatch, getState) => {
        const {currentPage, queries, pageSize} = getState().searchHistory;
        const indexSelectedCompany = _.findIndex(queries, {uuid: getState().searchHistory.selectedCompany.uuid});

        if (indexSelectedCompany > 0) {
            dispatch(selectCompany(queries[indexSelectedCompany - 1].uuid))
        } else {
            dispatch(loadSearchHistory(pageSize - 1, currentPage - 1));
        }
    }
}

export function loadSuggestions(inputObject) {
    return dispatch => {
        dispatch(loadSuggestionsBegin());

        ApiService
            .get(`/v1/query/autocomplete?q=${inputObject.value}`)
            .then((results) => {
                dispatch(updateSuggestions(results.data, inputObject.value))
            })
    };
}

export function previousCompanyExist() {
    return (dispatch, getState) => {
        const {currentPage, pageSize, queries, selectedCompany} = getState().searchHistory;
        const indexSelectedCompany = _.findIndex(queries, {uuid: selectedCompany.uuid});

        if (currentPage === 1 && indexSelectedCompany === 0) {
            return false
        }

        return true
    }
}

export function nextCompanyExist() {
    return (dispatch, getState) => {
        const {currentPage, pageSize, queries, selectedCompany, count} = getState().searchHistory;
        const indexSelectedCompany = _.findIndex(queries, {uuid: selectedCompany.uuid});

        if (currentPage >= (count / pageSize) && indexSelectedCompany === pageSize - 1) {
            return false
        }

        return true
    }
}

export function updateInputValue(value) {
    return {
        type: UPDATE_INPUT_VALUE,
        value
    };
}

export function clearSuggestions() {
    return {
        type: CLEAR_SUGGESTIONS
    };
}

function loadSuggestionsBegin() {
    return {
        type: LOAD_SUGGESTIONS_BEGIN
    };
}

function updateSuggestions(suggestions, value) {
    return {
        type: MAYBE_UPDATE_SUGGESTIONS,
        suggestions,
        value
    };
}

import React from "react";
import Loader from "react-loader-spinner";

class MyLoader extends React.Component {
    render(){
        return <Loader
                type="ThreeDots"
                color="#83abb7"
                height={100}
                width={100}

            />
    }
}
export default MyLoader

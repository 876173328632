import ApiService from "../../../lib/services/ApiService";
import { reduxLoaderActions } from "redux-state-loader";

export const SET_DATA = "accounts/SET_DATA";

export const fetchData = (page, pageSize, sorted) => {

    function getMappedAccountsData(data) {
        console.log(data.usage);
        console.log(data.accounts
            .map(a => {
                a.usage = data.usage[a.id];
                return a;
            }));
        return data.accounts;
    }

    return dispatch => {
        dispatch(reduxLoaderActions.startLoading('historyLoader'));
        ApiService
            .get(`/v1/account`)
            .then(result => {
                dispatch({
                    type: SET_DATA,
                    payload: {
                        accounts: getMappedAccountsData(result.data),
                    }
                });
                dispatch(reduxLoaderActions.stopLoading('historyLoader'));
            })
    };
};

const initialState = {
    accounts: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA:
            return {
                ...state,
                accounts: action.payload.accounts,
            };
        default:
            return state;
    }
};

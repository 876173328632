class BaseDecorator {
  constructor(data) {
    Object.assign(this, {
      uuid: data.id || null,
      ...this.transformData(data),
    })
  }

  transformData(data) {
    return data
  }
}

export default BaseDecorator

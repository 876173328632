import {
    SEARCH_HISTORY_LOADED,
    SELECTED_COMPANY,
    UPDATE_INPUT_VALUE,
    CLEAR_SUGGESTIONS,
    MAYBE_UPDATE_SUGGESTIONS,
    LOAD_SUGGESTIONS_BEGIN,
    UPDATED_CUSTOM_VALUES
} from "./actions";
import SearchedCompanyDecorator from "../../../lib/decorators/SearcherdCompanyDecorator";

const initialState = {
    count: 0,
    queries: [],
    selectedCompany: {},
    value: '',
    suggestions: [],
    isLoading: false,
    pageSize: 20,
    currentPage: 1
};

export function searchHistoryReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case SEARCH_HISTORY_LOADED: {
            return {
                ...state,
                count: action.payload.count,
                pageSize: action.pageSize,
                currentPage: action.currentPage,
                queries: action.payload.profiles.map(company => new SearchedCompanyDecorator(company))
            };
        }
        case SELECTED_COMPANY: {
            return {
                ...state,
                selectedCompany: action.payload,
                value: ''
            };
        }
        case UPDATED_CUSTOM_VALUES: {
            let company = state.selectedCompany;
            company.applyCustomValues(action.payload);
            return {
                ...state,
                selectedCompany: company
            }
        }
        case UPDATE_INPUT_VALUE:
            return {
                ...state,
                value: action.value
            };

        case CLEAR_SUGGESTIONS:
            return {
                ...state,
                suggestions: []
            };

        case LOAD_SUGGESTIONS_BEGIN:
            return {
                ...state,
                isLoading: true
            };

        case MAYBE_UPDATE_SUGGESTIONS:
            // Ignore suggestions if input value changed
            if (action.value !== state.value) {
                return {
                    ...state,
                    isLoading: false
                };
            }

            return {
                ...state,
                suggestions: action.suggestions.map(result => {
                    return { value: result.domain, label: result.name }
                }),
                isLoading: false
            };
        default:
            return state;
    }
}

import React from 'react'

export default (...props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="16"
            data-name="Layer 1"
            viewBox="0 0 9 16"
        >
            <path
                d="M7.3 8L.2 1.1A.4.4 0 01.1.4h.1L.4.1h.7l7.8 7.5A.6.6 0 019 8a.6.6 0 01-.1.4l-7.8 7.5H.4l-.2-.3a.5.5 0 010-.7z"
                className="cls-1"
            ></path>
        </svg>
    )
}



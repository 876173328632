import React from 'react';
import "./style.scss"

class LimitBelt extends React.Component {
    progressValues() {
        return {
            width: this.limitIsReached() ? '100%' : this.usage() + '%',
            backgroundColor: this.limitIsReached() ? '#b85767' : '#4fba81'
        }
    }

    limitIsReached(){
        return this.usage() >= 100
    }

    usage(){
        return (this.props.count / this.props.limit * 100).toFixed(0)
    }

    renderText(){
        if(this.limitIsReached()){
            return <div>You've reached your search limit. <a href='mailto:hello@getowl.co'>Contact us.</a></div>
        } else {
            return `You've got ${this.props.limit - this.props.count}/${this.props.limit} searches left`
        }
    }
    render(){
        return <div className="limit-belt">
            <div className="box">
                <div className="meter">
                    <span style={this.progressValues()}></span>
                </div>
                <div className="text">{this.renderText()}</div>
            </div>
        </div>
    }
}

export default LimitBelt;

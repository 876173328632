import ApiService from "./ApiService";

export function userData() {
    const user = localStorage.getItem('user');
    if(!user){
        window.location.href = '/login';
    }
    return JSON.parse(user).data
}

export function sendResetPasswordLink(email) {
    return ApiService
        .post('/v1/account/password/token', { email: email, url: window.location.hostname + '/new-password' })
}

export function setNewPassword(email, password, token) {
    return ApiService
        .post('/v1/account/password', {
            email: email,
            password: password,
            token: token
        })
}

export function logout() {
    localStorage.removeItem('user');
}

export function creditLimit() {
    return ApiService.get('/v1/query/usage')
}

export function login(email, password) {
    return ApiService.get('/user', { auth: { username: email.trim(), password: password } })
        .then(response => {
            localStorage.setItem('user', JSON.stringify(response));
        })
}

export function reloadUserLocalStorage() {
    return ApiService.get('/user')
        .then(response => {
            localStorage.setItem('user', JSON.stringify(response));
        })
}

export function activate(token) {
    return ApiService.post('v1/account/activation', { token: token})
}

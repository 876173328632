import ApiService from "../../../lib/services/ApiService";
import {reduxLoaderActions} from "redux-state-loader";

export const SET_DATA = "apikey/SET_DATA";

export const fetchApiKeys = () => {
    return dispatch => {
        dispatch(reduxLoaderActions.startLoading('apikey'));
        ApiService
            .get(`/v1/account/apikey`)
            .then(result => {
                console.log(result);
                dispatch({
                    type: SET_DATA,
                    payload: {
                        apikeys: result.data
                    }
                });
                dispatch(reduxLoaderActions.stopLoading('apikey'));
            })
    };
};

const initialState = {
    apikeys: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA:
            console.log('#############################333');
            console.log(action);
            return {
                ...state,
                apikeys: action.payload.apikeys,
            };
        default:
            return state;
    }
};
